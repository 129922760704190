<template>
  <!-- Login via google or slack -->
  <!-- Implement later -->
  <span></span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class ButtonLoginGoogle extends Vue {}
</script>
