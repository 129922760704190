
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import AppLogo from '@/components/common/AppLogo.vue'
import QForm from 'quasar/src/components/form/QForm.js';
import logging from '@/utils/logging'
import { localStore } from '@/utils/localstore'
import { APP_ROUTER_STORE_KEY } from '@/constants/vars'
import { ACTION_PROFILE } from '../user/profile-actions'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ui/ButtonLoginGoogle.vue'

@Options({
  components: {
    AppLogo,
    ButtonLoginGoogle,
  },
  directives: { maska },
})
export default class RegisterForm extends mixins(LoginRegisterMixin) {
  form = {
    displayName: '',
    email: '',
    password: '',
  }

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  async onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }
        this.doRegister()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doRegister() {
    const { email, displayName, password } = this.form
    const { dispatch } = this.$store
    const user = await dispatch(ACTION_PROFILE.REGISTER, { email, password, displayName })
    if (user) {
      this.redirectAfterLogin()
    }
  }

  redirectAfterLogin() {
    const redirectLogin = localStore.getItem(APP_ROUTER_STORE_KEY.REDIRECT_LOGIN)
    if (redirectLogin) {
      this.$router.push(redirectLogin)
    } else {
      this.$goto('home')
    }
  }
}
